<template>
  <div class="tooltip-content">
    <span class="tooltip-title">{{ t('bonuses.tooltipTitleCasino') }}</span>
    <div v-if="+freespin.rollingMultiplier > 0" class="tooltip-text">
      <p v-if="rewardCurrencyCode">
        {{ t('bonuses.rewardCurrency', { rewardCurrencyCode }) }}
      </p>
      <p v-if="freespin.maxRewardAmount" class="max-reward">
        {{
          t('bonuses.maxReward', {
            rewardAmount: freespin.maxRewardAmount,
            freespinCurrencyCode,
          })
        }}
      </p>
      <p>{{ t('bonuses.tooltipConditions') }}</p>
      <ol>
        <li>
          {{
            t('bonuses.tooltipPoint1', {
              wager: formatMultiplier(freespin.rollingMultiplier),
            })
          }}
        </li>
        <li>
          {{
            t('bonuses.tooltipPoint2', {
              freebetDuration: rollingDuration,
            })
          }}
        </li>
        <li v-if="freespin.rollingMultiplier && rollingRateMessage">
          {{ rollingRateMessage }}
        </li>
      </ol>
      <p v-if="freespin.rollingMultiplier">
        {{ t('bonuses.tooltipNotice') }}
      </p>
    </div>
    <NuxtI18nLink class="read-more" :to="readMoreUrl" target="_blank">
      {{ t('bonuses.readMore') }}
    </NuxtI18nLink>
  </div>
</template>

<script setup lang="ts">
import { useCurrencies } from '@st/payments/stores/currencies'
import type { Freespin } from '../../../types'
import { useRollingData } from '../../../composables/useRollingData'

const props = defineProps<{
  freespin: Freespin
}>()

const { t } = useI18n()
const { getCurrencyById } = useCurrencies()
const readMoreUrl = usePublicFileUrl('publicFileFreespinsTerms')

const { format: formatMultiplier } = useNumberFormatter()

const rewardCurrencyCode = computed(
  () => getCurrencyById(props.freespin.rewardCurrencyId)?.code,
)
const freespinCurrencyCode = computed(
  () => getCurrencyById(props.freespin.freespinCurrencyId)?.code,
)

const rollingData = computed(() => {
  const {
    rollingMultiplier,
    rollingDurationPeriodInHours: rollingPeriodInHours,
    rollingMinBetRate,
    rollingMaxBetRate,
    rollingCasinoCategoryIds,
  } = props.freespin

  return {
    rollingMultiplier,
    rollingPeriodInHours,
    rollingMinBetRate,
    rollingMaxBetRate,
    rollingCasinoCategoryIds,
  }
})

const { rollingDuration, rollingRateMessage } = useRollingData(rollingData)
</script>

<style scoped>
.tooltip-content {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  padding: var(--spacing-200) var(--spacing-150);
}

.tooltip-title {
  font: var(--desktop-text-sm-semibold);
  color: var(--text-primary);
}

.tooltip-text {
  margin-bottom: var(--spacing-150);
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);

  p {
    margin-top: var(--spacing-075);
    margin-bottom: 0;
  }

  ol {
    padding-left: var(--spacing-200);
  }
}

.max-reward {
  margin-top: 0 !important;
}

.read-more {
  cursor: pointer;
  color: var(--text-link);
  text-decoration: none;
}
</style>
