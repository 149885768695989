<template>
  <div class="tooltip-content">
    <span class="tooltip-title">{{ t('bonuses.tooltipTitle') }}</span>
    <div v-if="multiplier" class="tooltip-text">
      <p>{{ t('bonuses.tooltipConditions') }}</p>
      <ol>
        <li>
          {{ t('bonuses.tooltipPoint1', { wager: multiplier }) }}
        </li>
        <li v-if="freebet.freebetRollingSettings.rollingMultiplier">
          {{ t('bonuses.tooltipPoint2', { freebetDuration: rollingDuration }) }}
        </li>
        <li
          v-if="
            freebet.freebetRollingSettings.rollingMultiplier &&
            rollingRateMessage
          "
        >
          {{ rollingRateMessage }}
        </li>
      </ol>
    </div>
    <NuxtI18nLink class="read-more" :to="readMoreUrl" target="_blank">
      {{ t('bonuses.readMore') }}
    </NuxtI18nLink>
  </div>
</template>

<script setup lang="ts">
import type { Freebet } from '../../../types'
import { useRollingData } from '../../../composables/useRollingData'

const props = defineProps<{
  freebet: Freebet
}>()

const { t } = useI18n()
const readMoreUrl = usePublicFileUrl('publicFileFreebetTerms')

const rollingData = computed(() => {
  const {
    rollingMultiplier,
    rollingPeriodInHours,
    rollingMinBetRate,
    rollingMaxBetRate,
    rollingCasinoCategoryIds,
  } = props.freebet.freebetRollingSettings

  return {
    rollingMultiplier,
    rollingPeriodInHours,
    rollingMinBetRate,
    rollingMaxBetRate,
    rollingCasinoCategoryIds,
  }
})

const {
  rollingMultiplier: multiplier,
  rollingDuration,
  rollingRateMessage,
} = useRollingData(rollingData)
</script>

<style scoped>
.tooltip-content {
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.tooltip-title {
  font: var(--desktop-text-sm-semibold);
  color: var(--text-primary);
}

.tooltip-text {
  margin-bottom: var(--spacing-150);
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);

  p {
    margin-top: var(--spacing-075);
    margin-bottom: 0;
  }

  ol {
    margin-bottom: 0;
    padding-left: var(--spacing-200);
  }
}

.read-more {
  cursor: pointer;
  color: var(--text-link);
  text-decoration: none;
}
</style>
